import Item from "../types/Item";
export default class TodoApiService {

    async fetchData(domain: string, path: string, options: any = {}): Promise<Response> {
        const server = domain.replace(/\/$/g, '')
        return fetch(`${server}${path}`, options);
    }
  
    async getNodes(domain: string): Promise<Item[]> {
        const response = await this.fetchData(domain, `/items/`);
        if (response.ok) {
            const data = await response.json();
            const items = data.data;
            return items as Item[]
        }
        return Promise.reject(response.status);   
    }
    
    async saveItem(domain: string, item: Item): Promise<void> {
        await this.fetchData(domain, `/items/`, {
            method: 'POST',
            body: JSON.stringify(item),
        });
    }

    async deleteItem(domain: string, item: Item): Promise<void> {
        await this.fetchData(domain, `/items/${item.id}`, {
            method: 'DELETE',
            body: JSON.stringify(item),
        });
    }
}