import React, { useState, useEffect } from "react";
import Button from "@paprika/button";
import Input from "@paprika/input"
import Item from "../types/Item";
import ItemView from "./ItemView";
import "./List.scss";
import { useApi } from '../providers/TodoApiProvider';
import { v4 as uuidv4 } from 'uuid';

type Props = {};

export default function List(props: Props) {
  const [value, setValue] = React.useState(0); // integer state

  const getServer = () => {
    return window.localStorage.getItem('server') ?? 'https://325-o11y-assignment-yury-fedoseev-todo-api-main.highbond-s3.com/'; 
  }

  const api = useApi();
  const [items, setItems] = useState<Array<Item>>([]);
  const [server, setServer] = React.useState(getServer());

  useEffect(() => {
    async function getItems() {
        const items = await api.getNodes(getServer());
        setItems(items);
    }
    getItems();
 }, [])

  const onChangeURL = (event) => {
    const text = event.target.value;
    setServer(text);
    window.localStorage.setItem('server', text);
  }

  const addTodo = () => {
    items.map(item => {if (item.editable == true) { 
      onSaveItem(item); 
      item.editable = false;
    }});

    const ord = Math.max.apply(Math, items.map(function(item) { return item.order; })) + 1;
    const item = { id: uuidv4(), text: '', order: (ord < 1 ? 1 : ord), editable: true};
    setItems(items => items.concat([item]));
  }

  const onEditItem = async (todo) => {
    items.map(item => {if (item !== todo) item.editable = false});
  }

  const onSaveItem = async(item) => {
    await api.saveItem(getServer(), item);
  }

  const onDeleteItem = async(item) => {
    const newItems = items.filter(element => element !== item)
    setItems(newItems);
    await api.deleteItem(getServer(), item);
    // setValue(value => value + 1); 
  }

  return (
    <div className="list">
       <div className="todo-server">
        <Input onChange={onChangeURL} placeholder="Input server URL" size="medium" value={server}/>
      </div>
      <div> &nbsp;
      </div>
      <div className="todo-add">
        <Button kind="primary" onClick={addTodo}>
          Add Item 
        </Button>
      </div>
      {items.map((item, index) => {
        return (
          <div key={item.id} className="list-item">
            <ItemView item={item} 
              editItem={(item) => onEditItem(item)} 
              saveItem={(item) => onSaveItem(item)} 
              deleteItem={(item) => onDeleteItem(item)}></ItemView>
          </div>
        );
      })}
    </div>
  );
}
